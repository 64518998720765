<template>
    <div class="echarts moveTopAnimation1">
        <div id="mapEchart" style="height: 100%;width: 100%;"></div>
        <div class="map_name">
            <span @click="returnMap('china')" v-show="index != 0">{{ name }}</span>
            <span v-show="name1" @click="returnMap('province')"> > {{ name1 }}</span>
            <span v-show="name2"> > {{ name2 }}</span>
        </div>
        <div class="map_pointer_dataCenter">
            <div style="opacity: 0;">{{ doNothing }}</div>
            <!-- 省 -->
            <div class="map_pointer1 map_pointer_zhejiang" v-show="index === 0 && compamyShow['330000']">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['330000']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_sichuan" v-show="index === 0 && compamyShow['510000']">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['510000']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <!-- 市 -->
            <!-- 浙江下级 -->
            <div class="map_pointer1 map_pointer_hangzhou" v-show="index === 1 && compamyShow['330100']">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['330100']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_ningbo" v-show="index === 1 && compamyShow['330200']">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['330200']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_wenzhou" v-show="index === 1 && compamyShow['330300']">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['330300']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_jiaxing" v-show="index === 1 && compamyShow['330400']">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['330400']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_huzhou" v-show="index === 1 && compamyShow['330500']">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['330500']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_shaoxing" v-show="index === 1 && compamyShow['330600']">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['330600']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_jinhua" v-show="index === 1 && compamyShow['330700']">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['330700']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_quzhou" v-show="index === 1 && compamyShow['330800']">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['330800']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_zhoushan" v-show="index === 1 && compamyShow['330900']">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['330900']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_taizhou" v-show="index === 1 && compamyShow['331000']">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['331000']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_lishui" v-show="index === 1 && compamyShow['331100']">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['331100']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <!-- 四川下级 -->
            <div class="map_pointer1 map_pointer_chengdu" v-show="index === 1 && compamyShow['510100']">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['510100']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_zigong" v-show="index === 1 && compamyShow['510300']">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['510300']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_panzhihua" v-show="index === 1 && compamyShow['510400']">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['510400']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_luzhou" v-show="index === 1 && compamyShow['510500']">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['510500']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_deyang" v-show="index === 1 && compamyShow['510600']">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['510600']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_mianyang" v-show="index === 1 && compamyShow['510700']">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['510700']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_guangyuan" v-show="index === 1 && compamyShow['510800']">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['510800']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_suining" v-show="index === 1 && compamyShow['510900']">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['510900']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_nejiang" v-show="index === 1 && compamyShow['511000']">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['511000']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_leshan" v-show="index === 1 && compamyShow['511100']">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['511100']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_nanchong" v-show="index === 1 && compamyShow['511300']">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['511300']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_meishan" v-show="index === 1 && compamyShow['511400']">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['511400']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_yibin" v-show="index === 1 && compamyShow['511500']">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['511500']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_guangan" v-show="index === 1 && compamyShow['511600']">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['511600']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_dazhou" v-show="index === 1 && compamyShow['511700']">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['511700']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_yaan" v-show="index === 1 && compamyShow['511800']">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['511800']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_bazhong" v-show="index === 1 && compamyShow['511900']">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['511900']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_ziyang" v-show="index === 1 && compamyShow['512000']">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['512000']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_abazangzu" v-show="index === 1 && compamyShow['513200']">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['513200']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_ganzizangzu" v-show="index === 1 && compamyShow['513300']">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['513300']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_liangshanyizu" v-show="index === 1 && compamyShow['513400']">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['513400']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <!-- 区 -->
            <!-- 绍兴下级 -->
            <div class="map_pointer1 map_pointer_yuecheng" v-show="index === 2 && compamyShow['330602']" v-if="(330602 == companyItem.adCode && companyItem.companyLevel == 4)|| companyItem.companyLevel != 4">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['330602']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_keqiao" v-show="index === 2 && compamyShow['330603']" v-if="(330603 == companyItem.adCode && companyItem.companyLevel == 4)|| companyItem.companyLevel != 4">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['330603']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_shangyu" v-show="index === 2 && compamyShow['330604']" v-if="(330604 == companyItem.adCode && companyItem.companyLevel == 4)|| companyItem.companyLevel != 4">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['330604']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_xinchang" v-show="index === 2 && compamyShow['330605']" v-if="(330605 == companyItem.adCode && companyItem.companyLevel == 4)|| companyItem.companyLevel != 4">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['330605']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_zhuji" v-show="index === 2 && compamyShow['330606']" v-if="(330606 == companyItem.adCode && companyItem.companyLevel == 4)|| companyItem.companyLevel != 4">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['330606']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_shengzhou" v-show="index === 2 && compamyShow['330607']" v-if="(330607 == companyItem.adCode && companyItem.companyLevel == 4)|| companyItem.companyLevel != 4">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['330607']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <!-- 台州下级 -->
            <div class="map_pointer1 map_pointer_jiaojiang" v-show="index === 2 && compamyShow['331002']" v-if="(331002 == companyItem.adCode && companyItem.companyLevel == 4)|| companyItem.companyLevel != 4">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['331002']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_huangyan" v-show="index === 2 && compamyShow['331003']" v-if="(331003 == companyItem.adCode && companyItem.companyLevel == 4)|| companyItem.companyLevel != 4">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['331003']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_luqiao" v-show="index === 2 && compamyShow['331004']" v-if="(331004 == companyItem.adCode && companyItem.companyLevel == 4)|| companyItem.companyLevel != 4">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['331004']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_sanmen" v-show="index === 2 && compamyShow['331022']" v-if="(331022 == companyItem.adCode && companyItem.companyLevel == 4)|| companyItem.companyLevel != 4">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['331022']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_tiantai" v-show="index === 2 && compamyShow['331023']" v-if="(331023 == companyItem.adCode && companyItem.companyLevel == 4)|| companyItem.companyLevel != 4">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['331023']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_xianju" v-show="index === 2 && compamyShow['331024']" v-if="(331024 == companyItem.adCode && companyItem.companyLevel == 4)|| companyItem.companyLevel != 4">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['331024']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_wenling" v-show="index === 2 && compamyShow['331081']" v-if="(331081 == companyItem.adCode && companyItem.companyLevel == 4)|| companyItem.companyLevel != 4">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['331081']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_linhai" v-show="index === 2 && compamyShow['331082']" v-if="(331082 == companyItem.adCode && companyItem.companyLevel == 4)|| companyItem.companyLevel != 4">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['331082']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <div class="map_pointer1 map_pointer_yuhuan" v-show="index === 2 && compamyShow['331083']" v-if="(331083 == companyItem.adCode && companyItem.companyLevel == 4)|| companyItem.companyLevel != 4">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['331083']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
            <!-- 成都下级 -->
            <div class="map_pointer1 map_pointer_jinjiang" v-show="index === 2 && compamyShow['510104']">
                <div class="map_pointer_module_name">
                    <div class="map_pointer_module">
                        <div class="map_pointer_module_right">
                            <div v-for="item in compamyShow['510104']" :key="item.companyId">
                                <div>{{ item.companyName }}</div>
                                <div style="display: flex;">
                                    <div class="map_pointer_module_right_click" @click="toPath(item, 1)"> 进入官网 </div>
                                    <div class="map_pointer_module_right_click" style="margin-left: 20px;"  v-show="companyItem.companyLevel != 4" 
                                        @click="toPath(item, 2)"> 数据中心 </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <img src="../../static/dcImg/datacenter20.png" />
            </div>
        </div>
        <div class="img_bg" v-show="index == 0"><img src="../../static/dcImg/datacenter17.png" /></div>
        <div class="img_bg1" v-show="index == 0"><img src="../../static/dcImg/datacenter19.png" /></div>
        <img class="img_little_bg" v-show="index == 0" src="../../static/dcImg/datacenter18.png" />
    </div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-gl";
import chinaMap from "../../static/json/china.json";
import china_boundary from "../../static/json/china_boundary.json";
// 省
import zhejiang from "../../static/json/zhejiang.json";
import sichuan from "../../static/json/sichuan.json";
// 市
import shaoxing from "../../static/json/shaoxing.json";
import taizhou from "../../static/json/taizhou.json";
import chengdu from "../../static/json/chengdu.json";
import { getCompanyList, getCompanyDetailById } from "@/api/index.js";
export default {
    name: "dotMap",
    data() {
        return {
            myChart: null,
            index: 0,
            name: '中国 ',
            name1: '',
            name2: '',
            name3: '',
            companyItem: {},
            companyList: [],
            compamyShow: {},
            doNothing: ''
        }
    },
    mounted() {
        // 全国
        echarts.registerMap("china", { geoJSON: chinaMap });
        echarts.registerMap("china_boundary", { geoJSON: china_boundary });
        // 省
        echarts.registerMap("zhejiang", { geoJSON: zhejiang });
        echarts.registerMap("sichuan", { geoJSON: sichuan });
        // 市
        echarts.registerMap("shaoxing", { geoJSON: shaoxing });
        echarts.registerMap("taizhou", { geoJSON: taizhou });
        echarts.registerMap("chengdu", { geoJSON: chengdu });
        // TODO
        
        this.myChart = echarts.init(document.getElementById('mapEchart'))
        this.companyItem = this.$store.getters.getCompany;
        this.getCompanyList()
    },
    methods: {
        moveAm() {
            document.getElementsByClassName('moveLeft')[0].className += ' moveLeftAm'
            document.getElementsByClassName('moveRight')[0].className += ' moveRightAm'
            document.getElementsByClassName('moveCenter')[0].className += ' moveCenterAm'
            setTimeout(() => {
                document.getElementsByClassName('moveLeft')[0].className = 'left moveLeft'
                document.getElementsByClassName('moveRight')[0].className = 'right moveRight'
                document.getElementsByClassName('moveCenter')[0].className = 'left_total_data moveCenter'
            }, 1000)
        },
        initChart(mapName) {
            const areaColor = this.index == 0 ? 'transparent' : '#5B3607'
            const option = {
                geo: [
                    {
                        map: mapName,
                        zoom: 1.2,
                        top: '9%',
                        left: 'center',
                        roam: false,
                        regions: [],
                        itemStyle: {
                            normal: {
                                areaColor: areaColor
                            },
                        },
                        tooltip: {
                            show: false
                        },
                        z: 2
                    },
                ],
                series: []
            }
            if (this.index == 0) {
                option.series = [{
                    type: 'map',
                    map: mapName,
                    zoom: 1.2,
                    top: '9%',
                    left: 'center',
                    roam: false,
                    selectedMode: false,
                    tooltip: {
                        show: false
                    },
                    label: {
                        show: this.index != 0,
                        color: '#fff',
                    },
                    itemStyle: {
                        borderColor: '#FEFDE3',
                        borderWidth: 1,
                        shadowColor: '#5A4615',
                        shadowBlur: 20,
                        areaColor: 'rgba(255, 255, 255, 0)' // 透明度为 50% 的白色
                    },
                    emphasis: {
                        itemStyle: {
                            areaColor: 'rgba(0, 0, 0, 0.3)',
                        },
                        label: {
                            color: '#fff',
                        }
                    },
                    zlevel: 4,
                    data: [],
                }, {
                    type: 'map',
                    map: 'china_boundary',
                    zoom: 1.15,
                    top: '7%',
                    left: 'center',
                    roam: false,
                    selectedMode: false,
                    tooltip: {
                        show: false
                    },
                    label: {
                        show: this.index != 0,
                        color: '#fff',
                    },
                    itemStyle: {
                        borderColor: '#FEFDE3',
                        borderWidth: 2,
                        shadowColor: '#5A4615',
                        shadowBlur: 20,
                        areaColor: 'rgba(255, 255, 255, 0)' // 透明度为 50% 的白色
                    },
                    emphasis: {
                        disabled: true
                    },
                }]
            } else {
                option.series = [{
                    type: 'map',
                    map: mapName,
                    zoom: 1.2,
                    top: '9%',
                    left: 'center',
                    roam: false,
                    selectedMode: false,
                    tooltip: {
                        show: false
                    },
                    label: {
                        show: this.index != 0,
                        color: '#fff',
                    },
                    itemStyle: {
                        borderColor: '#FEFDE3',
                        borderWidth: 1,
                        // shadowColor: '#FFB753',
                        // shadowBlur: 20,
                        areaColor: '#322607' // 透明度为 50% 的白色
                    },
                    emphasis: {
                        itemStyle: {
                            areaColor: 'rgba(0, 0, 0, 0.3)',
                        },
                        label: {
                            color: '#fff',
                        }
                    },
                    zlevel: 4,
                    data: [],
                }]
            }
            this.myChart.setOption(option);
        },
        returnMap(mapName) {
            if (mapName == 'china') {
                this.name1 = ''
                this.name2 = ''
                this.name3 = ''
                this.getCompanyInfo(3)
                if (this.index !== 0) {
                    this.index = 0
                }
            }
            if (mapName == 'province') {
                this.name2 = ''
                this.name3 = ''
                this.getCompanyInfo(this.companyItem.pid)
                if (this.index !== 1) {
                    this.index = 1
                }
            }
        },
        toPath(item, type) {
            if (type == 1) {
                const company = item
                this.$store.commit("setCompany", company);
                if (company.companyLevel == 1) {
                    this.$router.push({ path: `/` });
                }
                if (company.companyLevel == 2) {
                    this.$router.push({ path: `/ProvincialCompany` });
                }
                if (company.companyLevel == 3) {
                    this.$router.push({ path: `/cityCompany` });
                }
                if (company.companyLevel == 4) {
                    this.$router.push({ path: `/areaCompany` });
                }
            } else if (type == 2) {
                const company = item
                this.$store.commit("setCompany", company);
                this.$parent.companyName = company.companyName
                this.companyItem = company
                this.$parent.initData()
                this.moveAm()
                if (item.companyLevel != 4) {
                    this.myChart.clear()
                    // 切换地图到点击省、市
                    this.initData()
                    this.getCompanyList()
                }

            }
        },
        getCompanyList() {
            this.compamyShow = {}
            // if(this.companyItem.companyLevel == 4){
            //     this.initData()
            //     return
            // }
            const id = this.companyItem.companyLevel == 4 ? this.companyItem.pid : this.companyItem.companyId
            getCompanyList({ current: 1, size: 100, pid: id }).then((res) => {
                this.companyList = res.data.list
                this.companyList.forEach(val => {
                    const adCode = val.adCode + ''
                    if (!this.compamyShow[adCode]) { // 没有这个省份
                        this.compamyShow[adCode] = []
                        this.compamyShow[adCode].push(val)
                    } else {
                        this.compamyShow[adCode].push(val)
                    }
                });
                this.doNothing = '无用'
                setTimeout(() => {
                    this.doNothing = ''
                }, 20)
                this.initData()
            });
        },
        initData() {
            if (this.companyItem.companyLevel == 1) {
                this.initChart('china')
            } else if (this.companyItem.companyLevel == 2) { // 省
                this.index = 1
                if (this.companyItem.adCode == "330000") {
                    this.name1 = ' 浙江省 '
                    this.initChart('zhejiang')
                }
                if (this.companyItem.adCode == "510000") {
                    this.name1 = ' 四川省 '
                    this.initChart('sichuan')
                }
            } else if (this.companyItem.companyLevel == 3 || this.companyItem.companyLevel == 4) { // 市
                this.index = 2
                // 浙江省下级
                if (this.companyItem.adCode == "330100") {
                    this.name1 = ' 浙江省 '
                    this.name2 = ' 杭州市 '
                    this.initChart('hangzhou')
                }
                if (this.companyItem.adCode == "330200") {
                    this.name1 = ' 浙江省 '
                    this.name2 = ' 宁波市 '
                    this.initChart('ningbo')
                }
                if (this.companyItem.adCode == "330300") {
                    this.name1 = ' 浙江省 '
                    this.name2 = ' 温州市 '
                    this.initChart('wenzhou')
                }
                if (this.companyItem.adCode == "330400") {
                    this.name2 = ' 嘉兴市 '
                    this.name1 = ' 浙江省 '
                    this.initChart('jiaxing')
                }
                if (this.companyItem.adCode == "330500") {
                    this.name1 = ' 浙江省 '
                    this.name2 = ' 湖州市 '
                    this.initChart('huzhou')
                }
                if (this.companyItem.adCode == "330600" || this.companyItem.adCode.substring(0, 4) == '3306') {
                    this.name1 = ' 浙江省 '
                    this.name2 = ' 绍兴市 '
                    this.initChart('shaoxing')
                    if(this.companyItem.adCode == '330602'){
                        this.name3 = '越城区'
                    }
                    if(this.companyItem.adCode == '330603'){
                        this.name3 = '柯桥区'
                    }
                    if(this.companyItem.adCode == '330624'){
                        this.name3 = '新昌县'
                    }
                    if(this.companyItem.adCode == '330683'){
                        this.name3 = '嵊州市'
                    }
                    if(this.companyItem.adCode == '330681'){
                        this.name3 = '诸暨市'
                    }
                    if(this.companyItem.adCode == '330604'){
                        this.name3 = '上虞区'
                    }
                }
                if (this.companyItem.adCode == "330700") {
                    this.name1 = ' 浙江省 '
                    this.name2 = ' 金华市 '
                    this.initChart('jinhua')
                }
                if (this.companyItem.adCode == "330800") {
                    this.name1 = ' 浙江省 '
                    this.name2 = ' 衢州市 '
                    this.initChart('quzhou')
                }
                if (this.companyItem.adCode == "330900") {
                    this.name1 = ' 浙江省 '
                    this.name2 = ' 舟山市 '
                    this.initChart('zhoushan')
                }
                if (this.companyItem.adCode == "331000" || this.companyItem.adCode.substring(0, 4) == '3310') {
                    this.name1 = ' 浙江省 '
                    this.name2 = ' 台州市 '
                    this.initChart('taizhou')
                    if(this.companyItem.adCode == '331023'){
                        this.name3 = '天台区'
                    }
                }
                if (this.companyItem.adCode == "331100") {
                    this.name1 = ' 浙江省 '
                    this.name2 = ' 丽水市 '
                    this.initChart('lishui')
                }
                // 四川省下级
                if (this.companyItem.adCode == "510100" || this.companyItem.adCode.substring(0, 4) == '5101') {
                    this.name1 = ' 四川省 '
                    this.name2 = ' 成都市 '
                    this.initChart('chengdu')
                    if(this.companyItem.adCode == '510104'){
                        this.name3 = '锦江区'
                    }
                }
                if (this.companyItem.adCode == "510300") {
                    this.name1 = ' 四川省 '
                    this.name2 = ' 自贡市 '
                    this.initChart('zigong')
                }
                if (this.companyItem.adCode == "510400") {
                    this.name1 = ' 四川省 '
                    this.name2 = ' 攀枝花市 '
                    this.initChart('panzhihua')
                }
                if (this.companyItem.adCode == "510500") {
                    this.name1 = ' 四川省 '
                    this.name2 = ' 泸州市 '
                    this.initChart('luzhou')
                }
                if (this.companyItem.adCode == "510600") {
                    this.name1 = ' 四川省 '
                    this.name2 = ' 德阳市 '
                    this.initChart('deyang')
                }
                if (this.companyItem.adCode == "510700") {
                    this.name1 = ' 四川省 '
                    this.name2 = ' 绵阳市 '
                    this.initChart('mianyang')
                }
                if (this.companyItem.adCode == "510800") {
                    this.name1 = ' 四川省 '
                    this.name2 = ' 广元市 '
                    this.initChart('guangyuan')
                }
                if (this.companyItem.adCode == "510900") {
                    this.name1 = ' 四川省 '
                    this.name2 = ' 遂宁市 '
                    this.initChart('suining')
                }
                if (this.companyItem.adCode == "511000") {
                    this.name1 = ' 四川省 '
                    this.name2 = ' 内江市 '
                    this.initChart('neijiang')
                }
                if (this.companyItem.adCode == "511100") {
                    this.name1 = ' 乐山市 '
                    this.name2 = ' 四川省 '
                    this.initChart('leshan')
                }
                if (this.companyItem.adCode == "511300") {
                    this.name1 = ' 四川省 '
                    this.name2 = ' 南充市 '
                    this.initChart('nanchong')
                }
                if (this.companyItem.adCode == "511400") {
                    this.name1 = ' 四川省 '
                    this.name2 = ' 眉山市 '
                    this.initChart('meishan')
                }
                if (this.companyItem.adCode == "511500") {
                    this.name2 = ' 宜宾市 '
                    this.name1 = ' 四川省 '
                    this.initChart('yibin')
                }
                if (this.companyItem.adCode == "511600") {
                    this.name2 = ' 广安市 '
                    this.name1 = ' 四川省 '
                    this.initChart('guangan')
                }
                if (this.companyItem.adCode == "511700") {
                    this.name2 = ' 达州市 '
                    this.name1 = ' 四川省 '
                    this.initChart('dazhou')
                }
                if (this.companyItem.adCode == "511800") {
                    this.name1 = ' 四川省 '
                    this.name2 = ' 雅安市 '
                    this.initChart('yaan')
                }
                if (this.companyItem.adCode == "511900") {
                    this.name1 = ' 四川省 '
                    this.name2 = ' 巴中市 '
                    this.initChart('bazhong')
                }
                if (this.companyItem.adCode == "512000") {
                    this.name1 = ' 四川省 '
                    this.name2 = ' 资阳市 '
                    this.initChart('ziyang')
                }
                if (this.companyItem.adCode == "513200") {
                    this.name1 = ' 四川省 '
                    this.name2 = ' 阿坝藏族羌族自治州 '
                    this.initChart('abazangzu')
                }
                if (this.companyItem.adCode == "513300") {
                    this.name1 = ' 四川省 '
                    this.name2 = ' 甘孜藏族自治州 '
                    this.initChart('ganzizangzu')
                }
                if (this.companyItem.adCode == "513400") {
                    this.name1 = ' 四川省 '
                    this.name2 = ' 凉山彝族自治州 '
                    this.initChart('liangshanyizu')
                }
                // TODO
            }
        },
        getCompanyInfo(id) {
            getCompanyDetailById(id).then((res) => {
                this.companyItem = res.data
                if (this.companyItem.companyLevel == 1) {
                    this.getCompanyList()
                    this.myChart.clear()
                    this.$store.commit("setCompany", this.companyItem);
                    this.$parent.companyName = this.companyItem.companyName
                    this.$parent.initData()
                    this.moveAm()
                }
                if (this.companyItem.companyLevel == 2) {
                    this.getCompanyList()
                    this.myChart.clear()
                    this.$store.commit("setCompany", this.companyItem);
                    this.$parent.companyName = this.companyItem.companyName
                    this.$parent.initData()
                    this.moveAm()
                }
                if (this.companyItem.companyLevel == 3) {
                    getCompanyDetailById(this.companyItem.pid).then((res1) => {
                        this.companyItem = res1.data
                        this.getCompanyList()
                        this.myChart.clear()
                        this.$store.commit("setCompany", this.companyItem);
                        this.$parent.companyName = this.companyItem.companyName
                        this.$parent.initData()
                        this.moveAm()
                    })
                }
            })
        }
    }
};
</script>
<style lang="scss" scoped>
.echarts {
    padding: 0 50px 10px 50px;
    position: relative;

    .map_name {
        position: absolute;
        top: 50px;
        left: 50px;
        color: #FFF8ED;

        span {
            cursor: pointer;
        }
    }

    .map_pointer_dataCenter {
        position: relative;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        z-index: 9;

        .map_pointer1 {
            position: absolute;

            .map_pointer_module_name {
                position: relative;
            }

            .map_pointer_module::before {
                content: " ";
                position: absolute;
                top: 65px;
                left: -50px;
                width: 40px;
                height: 2px;
                background-color: rgba(253, 189, 0, 0.7);
                transform: rotate(-45deg);
                transform-origin: 15px -5px;
            }

            .map_pointer_module::after {
                content: " ";
                position: absolute;
                top: 45px;
                left: -13px;
                width: 15px;
                height: 2px;
                background-color: rgba(253, 189, 0, 0.7);
            }

            .map_pointer_module {
                display: flex;
                align-items: flex-start;
                position: absolute;
                left: 50px;
                top: -70px;
                color: #fff;
                padding-left: 0;
                padding-top: 0;


                .map_pointer_module_right {
                    width: 190px;
                    background: rgba(0, 0, 0, 0.7);
                    font-size: 12px;
                    line-height: 23px;
                    padding: 10px;
                    border-left: 2px solid #FDBD00;

                    .map_pointer_module_right_click {
                        color: #FDBD00;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
            }
        }

        // 省
        .map_pointer_zhejiang {
            left: 610px;
            bottom: 190px;
        }

        .map_pointer_sichuan {
            left: 410px;
            bottom: 190px;

            .map_pointer_module::before {
                top: 58px;
                left: 230px;
                transform: rotate(45deg);
            }

            .map_pointer_module::after {
                left: 215px;
            }

            .map_pointer_module_right {
                border-left: 0 !important;
                border-right: 2px solid #FDBD00;
            }

            .map_pointer_module {
                left: -250px;
                top: -70px;
            }
        }

        // 市
        // 浙江
        .map_pointer_hangzhou {
            left: 300px;
            bottom: 425px;
        }

        .map_pointer_ningbo {
            left: 300px;
            bottom: 425px;
        }

        .map_pointer_wenzhou {
            left: 425px;
            bottom: 135px;
        }

        .map_pointer_jiaxing {
            left: 445px;
            bottom: 510px;
        }

        .map_pointer_huzhou {
            left: 345px;
            bottom: 520px;
        }

        .map_pointer_shaoxing {
            left: 410px;
            bottom: 390px;
        }

        .map_pointer_jinhua {
            left: 350px;
            bottom: 300px;
        }

        .map_pointer_quzhou {
            left: 205px;
            bottom: 280px;
        }

        .map_pointer_zhoushan {
            left: 595px;
            bottom: 415px;
        }

        .map_pointer_taizhou {
            left: 495px;
            bottom: 260px;
        }

        .map_pointer_lishui {
            left: 305px;
            bottom: 180px;
        }

        // 四川
        .map_pointer_chengdu {
            left: 452px;
            bottom: 345px;
        }

        .map_pointer_zigong {
            left: 500px;
            bottom: 236px;
        }

        .map_pointer_panzhihua {
            left: 339px;
            bottom: 71px;
        }

        .map_pointer_luzhou {
            left: 546px;
    bottom: 180px;

        }

        .map_pointer_deyang {
            left: 482px;
            bottom: 373px;
        }

        .map_pointer_mianyang {
            left: 499px;
            bottom: 432px;
        }

        .map_pointer_guangyuan {
            left: 568px;
            bottom: 432px;
        }

        .map_pointer_suining {
            left: 540px;
    bottom: 322px;

        }

        .map_pointer_nejiang {
            left: 503px;
            bottom: 263px;
        }

        .map_pointer_leshan {
            left: 438px;
    bottom: 219px;
        }

        .map_pointer_nanchong {
            left: 589px;
            bottom: 359px;
        }

        .map_pointer_meishan {
            left: 450px;
            bottom: 292px;
        }

        .map_pointer_yibin {
            left: 504px;
            bottom: 180px;
        }

        .map_pointer_guangan {
            left: 600px;
            bottom: 328px;
        }

        .map_pointer_dazhou {
            left: 664px;
            bottom: 377px;
        }

        .map_pointer_yaan {
            left: 391px;
            bottom: 268px;
        }

        .map_pointer_bazhong {
            left: 621px;
            bottom: 420px;
        }

        .map_pointer_ziyang {
            left: 523px;
            bottom: 292px;
        }

        .map_pointer_abazangzu {
            left: 379px;
            bottom: 437px;
        }

        .map_pointer_ganzizangzu {
            left: 264px;
            bottom: 304px;
        }

        .map_pointer_liangshanyizu {
            left: 366px;
            bottom: 124px;
        }


        // 区
        // 绍兴
        .map_pointer_yuecheng {
            left: 450px;
            bottom: 470px;
        }

        .map_pointer_keqiao {
            left: 450px;
            bottom: 330px;
        }

        .map_pointer_shangyu {
            left: 550px;
            bottom: 410px;
        }

        .map_pointer_xinchang {
            left: 570px;
            bottom: 95px;
        }

        .map_pointer_zhuji {
            left: 285px;
            bottom: 250px;
        }

        .map_pointer_shengzhou {
            left: 490px;
            bottom: 220px;
        }

        // 台州
        .map_pointer_jiaojiang {
            left: 520px;
            bottom: 305px;
        }

        .map_pointer_huangyan {
            left: 370px;
            bottom: 255px;
        }

        .map_pointer_luqiao {
            left: 530px;
            bottom: 231px;
        }

        .map_pointer_sanmen {
            left: 540px;
            bottom: 465px;
        }

        .map_pointer_tiantai {
            left: 346px;
            bottom: 494px;
        }

        .map_pointer_xianju {
            left: 215px;
            bottom: 340px;
        }

        .map_pointer_wenling {
            left: 510px;
            bottom: 155px;
        }

        .map_pointer_linhai {
            left: 443px;
            bottom: 363px;

        }

        .map_pointer_yuhuan {
            left: 452px;
            bottom: 73px;
        }
        // 成都下级
        .map_pointer_jinjiang{
            left: 464px;
            bottom: 232px;
        }
    }

    .img_bg {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 100px);
        height: 100%;
        padding: 0 50px 10px 50px;
        z-index: -1;

        img {
            width: 92%;
            transform: translate(32px, 7px);
            height: 89%;
        }
    }

    .img_bg1 {
        position: absolute;
        padding: 0 50px 10px 50px;
        width: calc(100% - 55px);
        top: 10px;
        left: -15px;
        z-index: -2;

        img {
            width: 92%;
            transform: translate(30px, -7px);
        }
    }

    .img_little_bg {
        position: absolute;
        bottom: 29px;
        left: 55.7%;
        width: 30px;
        z-index: -2;
    }
}
</style>